<template>
    <login-template />
</template>

<script>

// Components
import LoginTemplate from '@/components/login/LoginTemplate.vue'

export default {
  name: 'LoginView',

  components: {
    LoginTemplate
  }
}
</script>
