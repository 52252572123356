<template>
  <v-dialog v-model="dialog" width="30%">
    <v-form
      ref="forgotPasswordForm"
      @submit.prevent="sendPasswordReset"
    >
      <v-card>
        <v-card-title>Reset Password</v-card-title>
        <v-card-text>
          <!-- <v-text-field
            v-model="tenant"
            prepend-icon="mdi-briefcase"
            label="Tenant"
            :rules="requiredRule"
          /> -->
          <v-text-field
            v-model="email"
            prepend-icon="mdi-account"
            label="Email"
            :rules="requiredRule"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="closeDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="success"
            type="submit"
          >
            Send Password Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex';
export default {
  components: {
  },
  data: () => ({
    requiredRule: [(v) => !!v || 'Required'],
    dialog: false,
    email: ''
  }),
  computed: {
    ...mapState({
      storeTenant: (state) => state.login.tenant,
      storeEmail: (state) => state.login.email
    }),
    ...mapGetters({
      logo: 'app/logo'
    }),
    tenant: {
      get () {
        return this.storeTenant
      },
      set (value) {
        this.$store.dispatch('login/setTenant', value)
      }
    }
  },
  methods: {
    async sendPasswordReset () {
      const { valid } = await this.$refs.forgotPasswordForm.validate()
      if (valid) {
        this.$emit('loading', true)
        // TODO: Reenable
        // const resetPasswordResponse = await api.worker.resetPassword(email.value, tenant.value);

        // if (resetPasswordResponse == true) {
        //   emitter.emit('showSuccess', "Reset Password Email was Sent!");
        //   closeDialog();
        // } else {
        //   emitter.emit('showError', "Reset Password Failed!");
        // }
        this.$emit('loading', false)
      }
    },
    openDialog () {
      this.dialog = true
    },
    closeDialog () {
      this.$refs.forgotPasswordForm.reset()
      this.dialog = false
    }
  }
}
</script>
