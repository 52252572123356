<template>
  <v-container
    fluid
    class="fill-height"
  >
    <!-- align="center" -->
    <v-row class="fill-width align-center fill-height ">
      <v-col cols="4" />
      <v-col cols="4">
        <v-row
          class="mt-1"
          fill-width
        >
          <v-col
            cols="12"
            align="center"
          >
          <v-row>
            <v-col class="d-flex align-center justify-end pr-0">
              <img
                class="fill-width"
                :src="logo"
                height="250"
                aspect-ratio="1"
              >
            </v-col>
            <v-col class="d-flex align-center justify-start pl-0">
              <span class="text-h2" style="font-weight: 500">SoilTrace</span>
            </v-col>
          </v-row>
          </v-col>
          <v-col
            xs12
            sm10
            md6
            lg4
          >
            <v-form
              ref="loginForm"
              @submit.prevent="login"
            >
              <v-card class="elevation-0" outlined>
                <v-toolbar elevation="0" :style="`background-color: ${headerBackgroundColor}; ${isDarkMode ? 'border-bottom: 1px #4E4E4E solid' : ''}`">
                  <v-toolbar-title class="font-weight-bold">
                    Login
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    v-model="email"
                    prepend-icon="mdi-account"
                    label="Email"
                    type="email"
                    required
                    outlined
                    dense
                    class="mt-3"
                  />
                  <v-text-field
                    id="password"
                    v-model="password"
                    prepend-icon="mdi-lock"
                    label="Password"
                    type="password"
                    required
                    outlined
                    dense
                    hide-details
                    @keydown.enter="login()"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    text
                    dense
                    @click="openForgotPasswordDialog"
                  >
                    Forgot Password
                    <ForgotPasswordDialog ref="forgotPasswordDialog" />
                  </v-btn>
                  <v-btn
                    type="submit"
                    :color="loading ? 'disabled' : 'success'"
                    :loading="loading"
                    elevation="0"
                    width="100px"
                  >
                    <template v-slot:loader>
                      Loading...
                    </template>
                    Log In
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
            <v-row class="mt-0 pt-0 mx-3">
              <v-col class="text-caption text-left">
                &copy; {{ year }}, SoilTrace
              </v-col>
              <v-col class="text-caption text-right">
                Powered By <a
                  target="_blank"
                  href="https://balanced.plus/"
                >Balanced+</a>
              </v-col>
            </v-row>
            <!-- <v-row class="mt-0 pt-0">
              <v-col align="center">
                <a
                  href="https://apps.apple.com/us/app/systemx-app/id1592752376"
                  target="_blank"
                >
                  <img
                    style="height: 43px; margin-left: 30px;"
                    src="@/assets/login/download-on-the-app-store.png"
                    alt="Get it on Apple Store"
                  >
                </a>
              </v-col>
              <v-col align="center">
                <a
                  href="https://www.systemx.net/#lazy-loading-point"
                  target="_blank"
                >
                  <img
                    style="height: 63px;margin-top:-9px;"
                    src="@/assets/login/download_google_play_white.png"
                    alt="Get it on Google Play"
                  >
                </a>
              </v-col>
            </v-row> -->
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" />
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex';
// import { storeToRefs } from 'pinia'
import ForgotPasswordDialog from '@/components/login/ForgotPasswordTemplateDialog.vue'
// import { useLoginStore } from '@/stores/login'
// import { useAuthStore } from '@/stores/auth'
// import { useAppStore } from '@/stores/app'
// import router from '@/router/index.js'
// import { Field, Form } from 'vee-validate';

// stores
// const loginStore = useLoginStore()
// const authStore = useAuthStore()
// const appStore = useAppStore()

// emitter
export default {
  components: {
    ForgotPasswordDialog
  },
  data: () => ({
    requiredRule: [(v) => !!v || 'Required'],
    forgotPasswordDialog: false,
    loginForm: null,
    password: '',
    year: new Date().getFullYear(),
    loading: false

  }),
  computed: {
    ...mapState({
      storeTenant: (state) => state.login.tenant,
      storeEmail: (state) => state.login.email,
      isDarkMode: (state) => state.app.isDarkMode
    }),
    ...mapGetters({
      logo: 'app/logo',
      selectedCompanyProfile: 'auth/selectedCompanyProfile'
    }),
    tenant: {
      get () {
        return this.storeTenant
      },
      set (value) {
        this.$store.dispatch('login/setTenant', value)
      }
    },
    email: {
      get () {
        return this.storeEmail
      },
      set (value) {
        this.$store.dispatch('login/setEmail', value)
      }
    },
    headerBackgroundColor () {
      return this.isDarkMode ? '#1E1E1E' : 'rgb(238, 238, 238)'
    }
  },
  methods: {
    async login () {
      // this.$emit('loading', true)
      this.loading = true
      await this.$store.dispatch('auth/login', { username: this.email, password: this.password })
        .then(res => {
          if (res === true) {
            const defaultRoute = this.$router.options.routes.find(route => route.meta.defaultPageFor != null && route.meta.defaultPageFor.some(companyTypeId => companyTypeId === this.selectedCompanyProfile.companyTypeId))
            this.$router.push(defaultRoute.path)
            // router.push('/sites')
          }
          this.loading = false
        })
        .catch((e) => {
          this.$store.dispatch(
            'app/showError', 'Login Failed '
          )
          this.loading = false
          // this.$emit('showError', 'Login Failed!')
        })
        .error((e) => {
          this.loading = false
        })
      // this.$emit('loading', false)
    },
    openForgotPasswordDialog () {
      this.$refs.forgotPasswordDialog.openDialog()
    }
  }
}
</script>
